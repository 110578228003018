<template>
  <div
    component-name="grid-variants"
    ref="tpl_root"
    class="@container/grid-variants"
  >
    <div
      class="overflow-hidden grid grid-cols-2 @[900px]/grid-variants:grid-cols-3 @[1220px]/grid-variants:grid-cols-4 @2xl/grid-variants:grid-cols-5 gap-x-ui gap-y-3xl w-full"
    >
      <card-variant
        v-for="(item, index) in items"
        :key="`${index}-${item.button.title.toLowerCase().replace(/ /g, '')}`"
        :minimal-media="{
          ...item.minimal.asset,
          sizes,
          loading: loading === 'lazy' || index > 8 ? 'lazy' : 'auto',
        }"
        :ambient-media="{
          ...item.ambient.asset,
          sizes,
          loading: loading === 'lazy' || index > 8 ? 'lazy' : 'auto',
        }"
        :button="item.button"
        :indoor="item.indoor"
        :outdoor="item.outdoor"
        :atelier="item.atelier || undefined"
        :resource="item.resource || undefined"
        :use-remove="useRemove"
        :slug="item.slug"
        :use-link-exact-active="
          useLinkExactActive &&
          `/${$route.params.country}/${$route.params.locale}${item.button.action.to}` ===
            $route.fullPath
        "
        class="col-span-1"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  loading: {
    type: String,
    required: false,
    validator: function (value) {
      return ["auto", "lazy"].indexOf(value) !== -1;
    },
    default: "auto",
  },
  useRemove: {
    type: Boolean,
    required: false,
    default: false,
  },
  useLinkExactActive: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const tpl_root = templateRef("tpl_root");
const rootWidth = ref({
  value: 0,
});

onMounted(() => {
  nextTick(() => {
    const { width } = useElementSize(tpl_root);
    rootWidth.value = width.value;
    watch(width, (value) => {
      rootWidth.value = value;
    });
  });
});

const sizes = computed(() => {
  let size;

  if (rootWidth.value >= 1920) size = 500;
  else if (rootWidth.value >= 1220) size = 500;
  else if (rootWidth.value >= 900) size = 400;
  else if (rootWidth.value >= 600) size = 450;
  else size = 300;

  return { base: size };
});
</script>
